<style lang="scss" scoped>
@import "@assets/styles/variables.scss"; //公共样式变量
</style>

<template>
  <!-- 用户管理 -->
  <section class="page-child-contianer">
    <div class="table-box">
      <!-- 搜索表单 -->
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="工单名称">
          <el-input v-model="searchForm.name" placeholder="工单名称"></el-input>
        </el-form-item>

        <el-form-item label="状态">
          <el-select v-model="searchForm.status" placeholder="状态">
            <el-option label="全部" value="" />
            <el-option
              v-for="item in workOrderStatusList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="表单设置状态">
          <el-select v-model="searchForm.have_form" placeholder="表单设置状态">
            <el-option label="全部" value="" />
            <el-option
              v-for="item in workOrderApplyStatus"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="流程设置状态">
          <el-select v-model="searchForm.have_process" placeholder="流程设置状态">
            <el-option label="全部" value="" />
            <el-option
              v-for="item in workOrderApplyStatus"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch" icon="el-icon-search">
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <!-- 数据内容 -->
      <div class="table-container" id="tableContainer">
        <!-- 内页工具栏 -->
        <div class="tool-bar">
          <div class="tool-left">
            <el-button
              size="small"
              v-if="smallRoleObj.add"
              type="primary"
              icon="el-icon-plus"
              @click="$router.push({ name: 'WORK_ORDER_APPLY_REPORT_ADD' })"
            >
              添加
            </el-button>
          </div>
          <div class="tool-right">
            <el-tooltip effect="dark" content="刷新">
              <el-button
                size="small"
                icon="el-icon-refresh-right"
                @click="handleRefresh"
              />
            </el-tooltip>
            <el-tooltip effect="dark" content="全屏">
              <el-button size="small" @click="handleFullScreen">
                <span class="iconfont">
                  {{ (isFull && "&#xe641;") || "&#xe8fa;" }}
                </span>
              </el-button>
            </el-tooltip>
          </div>
        </div>
        <div class="table-data" ref="tableContainer">
          <!-- 内页表格数据 -->
          <el-table
            ref="tableBox"
            border
            style="width: 100%"
            :max-height="tableHeight"
            :data="tableData"
            v-loading="isLoading"
            @selection-change="handleSelectionChange"
          >
            <el-table-column prop="id" label="ID" width="80" align="center" />
            <el-table-column prop="name" label="工单名称" width="180" align="center" />
            <el-table-column prop="desc" label="备注" width="180" align="center" />
            <el-table-column prop="thumb_path_source" label="图标" align="center">
              <template slot-scope="scope">
                <img style="width: 50px" :src="scope.row.thumb_path_source" />
              </template>
            </el-table-column>
            <el-table-column prop="have_process_name" label="流程设置" align="center" />
            <el-table-column prop="have_form_name" label="表单设置" align="center" />
            <el-table-column prop="status_name" label="状态" align="center">
            </el-table-column>
            <el-table-column prop="do_member_name" label="操作人员" align="center" />
            <el-table-column label="更新时间" width="210" align="center">
              <template slot-scope="scope">
                <p>
                  {{ (scope.row.update_time * 1000) | formatTime("YYYY-MM-DD HH:mm:ss") }}
                </p>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="240" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button
                  class="color-primary"
                  type="text"
                  icon="el-icon-edit"
                  v-if="smallRoleObj.update"
                  @click="
                    $router.push({
                      name: 'WORK_ORDER_APPLY_REPORT_ADD',
                      params: { id: scope.row.id },
                    })
                  "
                >
                  编辑
                </el-button>
                <el-button
                  class="color-primary"
                  v-if="smallRoleObj.flow_design"
                  type="text"
                  icon="el-icon-finished"
                  @click="
                    $router.push({
                      name: 'WORK_ORDER_APPLY_REPORT_PROGRESS',
                      params: { id: scope.row.id },
                    })
                  "
                >
                  流程配置
                </el-button>

                <el-dropdown
                  @command="dropDownChanged"
                  v-if="smallRoleObj.form_design || smallRoleObj.delete"
                >
                  <span
                    class="el-dropdown-link"
                    style="margin-left: 10px; color: #1890ff"
                  >
                    <i class="el-icon-more"></i>更多
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-if="smallRoleObj.form_design"
                      :command="`0_${scope.row.id}`"
                      >表单配置</el-dropdown-item
                    >
                    <!-- <el-dropdown-item :command="`1_${scope.row.id}`"
                      >置顶</el-dropdown-item
                    > -->
                    <el-dropdown-item
                      v-if="smallRoleObj.delete"
                      :command="`2_${scope.row.id}`"
                      >刪除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页控件 -->
        <!-- <el-pagination
          background
          layout="total,sizes,prev, pager, next,jumper"
          :current-page="pagination.current"
          :page-size="pagination.size"
          :page-sizes="pagination.sizes"
          :total="pagination.total"
          @size-change="handleSize"
          @current-change="handleCurrent"
        >
        </el-pagination> -->
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { workOrderProgressPageList, workOrderProgressDel } from "@api/workOrderProgress";
import { PAGE_SIZE, PAGE_SIZES } from "@config";
export default {
  name: "workOrderProgress",
  inject: ["reload"],
  data() {
    return {
      tableHeight: 0, //表格最大高度
      searchForm: {}, //搜索表单
      isFull: false, //全屏开启
      tableData: [], //表格数据
      multipleSelection: [], //表格多选储存数据
      isLoading: false,
      workOrderStatusList: [
        {
          id: 1,
          name: "启用",
        },
        {
          id: 2,
          name: "禁用",
        },
      ],
      workOrderApplyStatus: [
        {
          id: 1,
          name: "已设置",
        },
        {
          id: 2,
          name: "未设置",
        },
      ],
      companyList: [], //单位列表
      workOrderList: [], //工单(类型)列表
      projectList: [], //项目列表

      pickerOptions: {
        //时间段组件配置
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: [], //时间段默认时间
      smallRoleObj: {},
      pagination: {
        page: 1,
        total: 0,
        current: 1,
        size: PAGE_SIZE,
        sizes: PAGE_SIZES,
      }, //分页数据
    };
  },
  computed: {
    ...mapState("system", ["branchListSearch"]),
  },
  async created() {
    // 获取初始化数据
    let tempParams = {
      page: this.pagination.page,
      rows: this.pagination.size,
    };
    this.getData(tempParams);
    /**
     * 页面dom元素加载完后调用，计算表格最大高度
     */
    this.$nextTick(function () {
      this.initTableHeight();
    });
  },
  async mounted() {
    window.addEventListener("resize", () => {
      // 窗口尺寸改变时触发事件
      if (!this.$screenfull.isFullscreen) {
        this.isFull = false;
      }
      this.initTableHeight();
    });
    this.smallRoleObj = await this.validateSmallRole();
    this.$forceUpdate();
  },
  methods: {
    dropDownChanged(command) {
      let type = command.split("_")[0];
      let id = command.split("_")[1];
      switch (parseInt(type)) {
        case 0:
          this.$router.push({
            name: "WORK_ORDER_APPLY_REPORT_FORM",
            params: { id: id },
          });
          break;
        case 1:
          break;
        case 2:
          this.handleDelete(id);
          break;
      }
    },
    /** 删除 */
    handleDelete(id) {
      /** 弹窗再次确认操作 */
      this.$confirm("此操作将永久删除该工单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        /** 确认触发 */
        .then(async () => {
          /**
           * 请求接口删除数据
           */
          let res = await workOrderProgressDel({ id: id });
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: res.msg,
            });
          }

          this.getData();
        })
        /** 取消触发 */
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除操作",
          });
        });
    },
    /** 获取表格数据 */
    async getData(tempParams) {
      tempParams = {
        ...this.searchForm,
        ...tempParams,
      };
      this.isLoading = true;
      /**
       * 请求接口数据
       */
      let res = await workOrderProgressPageList(tempParams);
      this.tableData = res.data;
      this.pagination.total = res.data.length;
      this.isLoading = false;
      this.$forceUpdate();
    },
    /** 分页参数-每页显示条数改变 */
    handleSize(val) {
      this.pagination = {
        ...this.pagination,
        size: val,
      };
      let tempParams = {
        ...this.searchForm,
        page: this.pagination.page,
        rows: this.pagination.size,
      };
      this.getData(tempParams);
    },
    /** 分页参数-当前页改变 */
    handleCurrent(val) {
      this.pagination = {
        ...this.pagination,
        page: val,
      };
      let tempParams = {
        ...this.searchForm,
        page: this.pagination.page,
        rows: this.pagination.size,
      };
      this.getData(tempParams);
    },
    /** 查询 */
    handleSearch() {
      this.pagination = {
        ...this.pagination,
        page: 1,
      };
      let tempParams = {
        ...this.searchForm,
        page: this.pagination.page,
        rows: this.pagination.size,
      };
      this.getData(tempParams);
    },
    /** 刷新页面 */
    handleRefresh() {
      this.reload();
    },
    /** 初始化表格高度 */
    initTableHeight() {
      if (this.$refs["tableContainer"]) {
        this.tableHeight = this.$refs["tableContainer"].offsetHeight + 100;
      }
    },
    /** 全屏 */
    handleFullScreen() {
      const element = document.getElementById("tableContainer");
      this.isFull = !this.isFull;
      if (this.isFull) {
        this.$screenfull.request(element);
      } else {
        this.$screenfull.exit(element);
      }
    },
    /** 表格多选触发 */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCancle() {
      this.dialogFormVisible = false;
      this.mobileUpdateId = 0;
      this.mobileUpdateForm = {};
    },
  },
};
</script>
